import styled from 'styled-components';

const Container = styled.section`
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.maxWidth || '1024px'};

  a {
    font-weight: 500;
  }
`;

export default Container;
