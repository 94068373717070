import React from 'react';

import Layout from '../components/layout';
import { Container } from '../components/layoutComponents';
import FAQ from '../components/FAQ';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <Container>
      <SEO
        title="Home"
        keywords={[`starting`, `learn`, `security`, 'hacking', 'ethical']}
      />
      <FAQ />
    </Container>
  </Layout>
);

export default IndexPage;
