import React from 'react';
import useCollapse from 'react-collapsed';
import { Text, Box, Flex } from 'rebass';
export default function Collapse(props) {
  const { getCollapseProps, getToggleProps, isOpen } = useCollapse();
  return (
    <Box
      p={2}
      py={3}
      backgroundColor="white"
      css={{
        boxShadow: '2px 2px 10px -5px rgba(0, 0, 0, 0.3)',
        border: '1px solid rgba(0,0,0,0.05)',
        borderRadius: 20
      }}
      {...props}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        onClick={getToggleProps().onClick}
        css={{ cursor: 'pointer' }}
      >
        <Text fontSize={3} fontWeight={600} px={3} my={2}>
          {props.title}
        </Text>
        <Text
          fontSize={2}
          px={2}
          css={{
            opacity: 0.8,
            transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'all 0.3s'
          }}
        >
          ►
        </Text>
      </Flex>
      <Flex width={1} {...getCollapseProps()}>
        <Text fontSize={2} fontWeight={400} px={3} my={2}>
          {props.children}
        </Text>
      </Flex>
    </Box>
  );
}
