import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { Container } from './layoutComponents';

const Header = ({ siteTitle }) => {
  return (
    <StyledHeader>
      <svg
        width="100vw"
        height="650"
        viewBox="0 0 500 80"
        preserveAspectRatio="none"
        style={{ position: 'absolute', zIndex: -100 }}
      >
        <defs>
          <clipPath id="shape">
            <path d="M0,0 L0,40 Q250,60 500,40 L500,0 Z" />
          </clipPath>
          <linearGradient x1="0%" y1="100%" x2="100%" y2="0%" id="Gradient01">
            <stop offset="0%" stopColor="#4C36CF" />
            <stop offset="100%" stopColor="#5D40FE" />
          </linearGradient>
        </defs>
        <rect
          x="0"
          y="0"
          width="500"
          height="80"
          fill="url(#Gradient01)"
          clipPath="url(#shape)"
        />
      </svg>
      <Container>
        <Flex justifyContent="space-between" alignItems="center" my={3} px={3}>
          <h1>
            <Link to="/">{siteTitle}</Link>
          </h1>
          <Flex>
            <h2>
              <Link to="/about">About Me</Link>
            </h2>
          </Flex>
        </Flex>
        <Flex
          width={1}
          alignItems="center"
          my={5}
          flexDirection="column"
          className="hero"
          // style={props}
        >
          <Flex
            alignItems="center"
            fontSize={2}
            color="text-green"
            px={2}
            my={4}
            css={{
              height: 1,
              borderLeft: '35px solid #fff',
              borderRight: '35px solid #fff'
            }}
          >
            <Text fontSize={7} fontWeight={600} m={[3]}>
              FAQ
            </Text>
          </Flex>
          <Text fontSize={2} fontWeight={300} m={[1, 2]} textAlign="center">
            Answers to a list of frequently asked questions stumbled upon while
            learning security, <br />
            curated by Shashank (
            <a href="https://twitter.com/cyberboyIndia">@cyberboyindia</a>)
          </Text>
        </Flex>
      </Container>
    </StyledHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

const StyledHeader = styled.header`
  height: 350px;
  color: white;
  margin-bottom: 1rem;
  h1,
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0;
    opacity: 0.8;
    transition: opacity 0.3s;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  h2 {
    font-size: 16px;
    padding: 0 0px;
  }
  .hero {
    a {
      opacity: 0.7;
      transition: opacity 0.3s;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

export default Header;
