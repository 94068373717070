import React from 'react';
import styled from 'styled-components';
import { Flex, Text, Button } from 'rebass';
import { Container } from './layoutComponents';

const Footer = ({ className, newletter = true }) => (
  <footer className={className}>
    <Container>
      <Flex
        p={4}
        my={5}
        style={{ height: '150px' }}
        alignItems="center"
        justifyContent={['center', 'space-around']}
        flexDirection={['column', 'row']}
      >
        <Flex alignItems="center" flexDirection="column">
          <Text color="white" fontSize={[3, 4]} py={1}>
            SUBSCRIBE TO NEWSLETTER
          </Text>
          <Text color="white" fontSize={2} py={1} pb={[4, 0]}>
            Get notified when there's newer content
          </Text>
        </Flex>
        <form
          name="Newsletter Form"
          method="POST"
          data-netlify="true"
          action="/thank-you"
        >
          <input type="hidden" name="form-name" value="Newsletter Form" />
          <Flex
            bg="white"
            style={{ borderRadius: 8, border: '2px solid white' }}
          >
            <input
              name="email"
              type="email"
              required
              style={{ width: '220px', outline: 'none', padding: '0 10px' }}
              placeholder="john@doe.com"
            />
            <Button
              type="submit"
              style={{ color: 'white', background: '#4c36cf' }}
              width="160px"
            >
              SUBSCRIBE
            </Button>
          </Flex>
        </form>
      </Flex>
      <Flex alignItems="center" flexDirection="column">
        <Text color="white" fontSize={1} px={4}>
          Curated with{' '}
          <span role="img" aria-label="purple-heart">
            💜
          </span>
          by Shashank (
          <a href="https://twitter.com/cyberboyIndia">@cyberboyindia</a>)
        </Text>
        <Text color="white" fontSize={1} pb={[2, 0]}>
          Built with <a href="https://www.gatsbyjs.org">gatsby</a> by Soumik (
          <a href="https://twitter.com/soumikcv">@soumikcv</a>)
        </Text>
      </Flex>
    </Container>
  </footer>
);

const StyledFooter = styled(Footer)`
  color: #fffaff;
  background-image: linear-gradient(to right, #4c36cf, #5d40fe);
  padding: 1rem 0;
  a {
    opacity: 0.7;
    transition: opacity 0.3s;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
`;

export default StyledFooter;
