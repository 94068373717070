import React from 'react';
import { Text } from 'rebass';

export default [
  {
    question: 'What is BugBounty?',
    answer: (
      <Text>
        A bug bounty is an overgrowing trend in which security enthusiasts
        participate from all around the world crowdsourcing to find (a) bug's in
        a particular program or engagement called as a Bug Bounty Program. The
        "bounty" ensures that a researcher gets paid either in Cash or in swags
        as a compensation for their efforts in keeping the organization secure.
      </Text>
    )
  },
  {
    question: 'Basic Technical things to know before getting started?',
    answer: (
      <Text>
        There are a few basic technical things you should know before getting started. Before learning how to find security vulnerabilities in a website one should know how a website works and how it’s built. Here is a small list of resources to learn about them. 
        <br />
        <br />
        HTTP methods:
        <br />
        It’s important to know how a website communicate. The Hypertext Transfer
        Protocol (HTTP) is designed to enable communications between
        clients(eg:- browser) and the server:{' '}
        <a href=" https://www.tutorialspoint.com/http/http_methods.htm">
          https://www.tutorialspoint.com/http/http_methods.htm
        </a>
        <br />
        <br />
        TCP IP model:{' '}
        <br />
        <a href="https://www.geeksforgeeks.org/tcp-ip-model/">
          https://www.geeksforgeeks.org/tcp-ip-model/
        </a>
        <br />
        <br /> Basic Linux Commands:{' '}
        <br />
        <a href="https://www.codecademy.com/learn/learn-the-command-line">
          https://www.codecademy.com/learn/learn-the-command-line
        </a>
        <br />
        <a href="https://www.tutorialspoint.com/operating_system/os_linux.htm">
          https://www.tutorialspoint.com/operating_system/os_linux.htm
        </a>
        <br />
        <br /> Basic server and network concepts:{' '}
        <br />
        <a href="https://nceas.github.io/oss-lessons/servers-networks-command-line/1-servers-net.html">
          https://nceas.github.io/oss-lessons/servers-networks-command-line/1-servers-net.html
        </a>
        <br />
        <a href="https://commotionwireless.net/docs/cck/networking/learn-networking-basics/">
          https://commotionwireless.net/docs/cck/networking/learn-networking-basics/
        </a>
        <br />
        <br />
        Basic to advance Web Application programming. (Check the other FAQ){' '}
      </Text>
    )
  },
  {
    question: 'Knowing programming language is necessary?',
    answer: (
      <Text>
        Yes, at least basic programming knowledge is necessary. It’s easier to find bugs in a Web Application when you know how to create one. 
        <br />
        <br />
        Make sure you have practical knowledge of HTML, Javascript, PHP/NodeJS and its integration with Mysql/MongoDB.
        <br />
        Note:- Do not simply read and learn the above-mentioned languages instead, try creating simple simple applications using all of them, so you get a better understanding of how a web application works. 
        <br />
        For HTML, JS and PHP:{' '}
        <br />
        <a href=" https://www.w3schools.com">
          https://www.w3schools.com
        </a>
        <br />
        <br />
        For Nodejs and MongoDB
        <br />
        Express in action by Evan M. Hahn
        <br /> 
        <a href="https://www.amazon.com/Express-Action-Writing-building-applications/dp/1617292427">
          https://www.amazon.com/Express-Action-Writing-building-applications/dp/1617292427
        </a>
        <br />
        <br />
        Apart from web languages having a good grasp of one scripting language is really helpful for writing your own automation scripts. In certain scenarios you can automate your logic and find security bugs faster where repetitive task is involved. 
        <br />
        <a href="https://www.codecademy.com/learn/learn-python">
          https://www.codecademy.com/learn/learn-python
        </a>
      </Text>
    )
  },
  {
    question: 'How to get started with web pentesting?',
    answer: (
      <Text>
      For starters it's best to familiarise yourself with OWASP Top 10.
      <br />
      <br />
      Owasp top 10
      <br />
      <a href="https://www.owasp.org/index.php/Category:OWASP_Top_Ten_Project#OWASP_Top_10_for_2010">
      https://www.owasp.org/index.php/Category:OWASP_Top_Ten_Project#OWASP_Top_10_for_2010 </a>
      <br />
      <br />
      Owasp Top 10 2013
      <br />
      <a href="https://www.owasp.org/index.php/Category:OWASP_Top_Ten_Project#OWASP_Top_10_for_2013">
      https://www.owasp.org/index.php/Category:OWASP_Top_Ten_Project#OWASP_Top_10_for_2013 </a>
      <br />
      <br />
      Owasp Top 10 2017
      <br />
      <a href="https://www.owasp.org/images/7/72/OWASP_Top_10-2017_%28en%29.pdf.pdf">
      https://www.owasp.org/images/7/72/OWASP_Top_10-2017_%28en%29.pdf.pdf </a>
      <br />
      <br />
      Owasp testing guide
      <br />
      <a href="https://www.owasp.org/images/1/19/OTGv4.pdf">
      https://www.owasp.org/images/1/19/OTGv4.pdf </a>
      <br />
      </Text>
    )
  },
    {
    question: 'Which books should I read ?',
    answer: (
      <Text>
      The web application hackers handbook
      <br />
      <a href="https://www.amazon.in/Web-Application-Hackers-Handbook-Exploiting/dp/8126533404">
      https://www.amazon.in/Web-Application-Hackers-Handbook-Exploiting/dp/8126533404 </a>
      <br />
      <br />
      Mastering Modern Web Penetration Testing
      <br />
      <a href="https://www.amazon.in/Mastering-Modern-Web-Penetration-Testing/dp/1785284584">
      https://www.amazon.in/Mastering-Modern-Web-Penetration-Testing/dp/1785284584 </a>
      <br />
      <br />
      Web Hacking 101
      <br />
      <a href="https://leanpub.com/web-hacking-101">
      https://leanpub.com/web-hacking-101 </a>
      <br />
      <br />
      The Tangled Web: A Guide to Securing Modern Web Applications Book by Michał Zalewski
      <br />
      <a href="https://www.amazon.in/Tangled-Web-Securing-Modern-Applications/dp/1593273886">
      https://www.amazon.in/Tangled-Web-Securing-Modern-Applications/dp/1593273886 </a>
      <br />
      </Text>
    )
  },
  {
    question: 'Where can I practice the vulnerabilities?',
    answer: (
      <Text>
      DVWA
      <br />
      Damn Vulnerable Web App (DVWA) is a PHP/MySQL web application that is damn vulnerable. Its main goals are to be an aid for security professionals to test their skills and tools in a legal environment, help web developers better understand the processes of securing web applications and aid teachers/students to teach/learn web application security in a class room environment.
      <br />
      <a href="http://www.dvwa.co.uk">
      http://www.dvwa.co.uk </a>
      <br />
      <br />
      You can also start plaging CTF's (Capture the Flag). Below is the list of websites where you can play CTFs
      <br />
      <a href="https://www.hacker101.com">
      https://www.hacker101.com</a>
      <br />
      <a href="https://pentesterlab.com">
      https://pentesterlab.com</a>
      <br />
      <a href="https://www.hackthebox.eu">
      https://www.hackthebox.eu</a>
      <br />

      </Text>
    )
  },
  {
    question: 'Where I can find programs which pays bounty?',
    answer: (
      <Text>
      <a href="https://hackerone.com">
      http://hackerone.com </a>
      <br />
      <a href="https://bugcrowd.com">
      http://bugcrowd.com </a>
      <br />
      <a href="https://cobalt.io">
      http://cobalt.io </a>
      <br />
      <a href="https://hackenproof.com">
      https://hackenproof.com </a>
      <br />
      <a href="https://www.intigriti.com">
      https://www.intigriti.com </a>
      <br />
      <a href="https://yogosha.com">
      http://yogosha.io </a>
      <br />
      <a href="https://www.yeswehack.com">
      https://www.yeswehack.com </a>
      <br />
      <a href="https://bugbounty.jp">
      https://bugbounty.jp </a>
      <br />
      </Text>
    )
  },
  {
    question: 'Which tools should I master?',
    answer: (
      <Text>
      Burp suite:
      <br />
      Burp Suite is a multi-functional tool which is a gold mine for all bug hunters. It can simultaneously aid you as an intercepting proxy and also spider the website you’re working on to find bugs automatically. It has one of the most powerful scanner which further makes your task easier. Apart from that it also supports pen testing web sockets. It’ll take us more than a simple FAQ to list all of its functions, its better to check out the tool.
      <br />
      <a href="https://portswigger.net/burp">
      https://portswigger.net/burp </a>
      </Text>
    )
  },
  {
    question: 'Which  OS is suitable for hacking?',
    answer: (
      <Text>
      It's a miconception that using Kali Linux makes you a hacker. Kali Linux is an OS with a collection of all open source tools used for security testing. 
      The first step is to learn the logics and then automation. Relying fully on automation without understanding the work behind the scene will never be useful in 
      longer run. Its generally suggested to use unix platforms. So any OS like Ubuntu, Debian or MacOS would be good. 
      </Text>
    )
  },
  {
    question: 'Where I can find write-ups of bugs?',
    answer: (
      <Text>
      Read hackerone public disclosures. 
      <br />
      <a href="http://h1.nobbd.de">
      http://h1.nobbd.de </a>
      <br />
      </Text>
    )
  }             
];
