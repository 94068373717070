import React from 'react';
import { Flex } from 'rebass';
import { useSpring, animated } from 'react-spring';

import Collapse from './Shared/Collapse';
import Container from './Shared/Container';

import ContentList from '../content';
export default function FAQ() {
  const props = useSpring({
    from: { opacity: 0, transform: 'translateY(30px)' },
    opacity: 1,
    transform: 'translateY(0px)'
    // delay: 100
  });
  const AnimatedFlex = animated(Flex);
  return (
    <Container
      maxWidth="950px"
      style={{ transform: 'translate3d(0,-100px,0)' }}
    >
      <AnimatedFlex
        style={props}
        flexDirection="column"
        alignItems="center"
        pt={5}
        px={[2, 4]}
      >
        {ContentList.map(content => (
          <Collapse
            key={content.question}
            my={2}
            width={[1, 4 / 5]}
            title={content.question}
          >
            {content.answer}
          </Collapse>
        ))}
      </AnimatedFlex>
    </Container>
  );
}
